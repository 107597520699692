<div class="image-grid-view">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h2>{{
        "components.image-preview.IMAGES_LIST_HEADER" | translate : {imagePreviewItemArrayLength: this.imagePreviewItemArrayLength}
      }}</h2>
      <div *ngIf="isShowControlName && previewHeader">{{ previewHeader }}</div>
    </div>
    <div class="panel-body">
      <cdk-virtual-scroll-viewport itemSize="98" class="virtual-scroll-viewport">
        <div class="images-row" *cdkVirtualFor="let imagesRow of imageRows; let rowIndex = index">
          <div class="image-item" *ngFor="let image of imagesRow; let columnIndex = index">
            <image-preview
              [imagePreviewData]="getImagePreviewData(image)"
              [isShowHeader]="false"
              [previreModalDisplayMode]="PreviewDisplayMode.Carousel">
            </image-preview>
            <div class="image-details">
              <div class="image-index">{{ rowIndex * columnsCount + columnIndex + 1 }}</div>
              <span class="image-name" ellipsis-title [lines]="2">{{ image.name }}</span>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
