import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImagePreviewData, ImagePreviewItem } from '../model/image-preview.model';
import { PreviewDisplayMode } from '../model/preview-display-mode.enum';

@Component({
	selector: 'image-preview-display-modal',
	templateUrl: './image-preview-display-modal.component.html',
	styleUrls: ['./image-preview-display-modal.component.scss']
})
export class ImagePreviewDisplayModalComponent implements OnInit {

  @Input() imagePreviewData: ImagePreviewData;
  @Input() previewModalDisplayMode: PreviewDisplayMode;
  
  public imagePreviewItemArray: ImagePreviewItem[];
  public PreviewDisplayMode = PreviewDisplayMode;
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  	this.imagePreviewItemArray = this.imagePreviewData.previewImages;
  }

  closeModal() {
  	this.bsModalRef.hide();
  }

}
