import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from '../../optiLogicModal/optiLogicModal.service';
import { ImagePreviewDisplayModalComponent } from '../image-preview-display-modal/image-preview-display-modal.component';
import { PreviewDisplayMode } from '../model/preview-display-mode.enum';
import { ImagePreviewData, ImagePreviewItem } from './../model/image-preview.model';

@Component({
	selector: 'image-preview-carousel-modal',
	templateUrl: './image-preview-carousel-modal.component.html',
	styleUrls: ['./image-preview-carousel-modal.component.scss']
})
export class ImagePreviewCarouselModalComponent implements OnInit {
  @Input() imagePreviewData: ImagePreviewData;
  public imagePreviewItemArray: ImagePreviewItem[];
  public previewItemArrayLength: number;
  public currentItemIndex: number = 0;
  public imageError: boolean = false;
  public previewNotAvailableMessage: string;
  constructor(private translateService: TranslateService, private bsModalRef: BsModalRef, private optiLogicModalService: OptiLogicModalService) { }

  ngOnInit(): void {
  	this.imagePreviewItemArray = this.imagePreviewData.previewImages;
  	this.previewItemArrayLength = this.imagePreviewItemArray.length;
  	this.previewNotAvailableMessage = this.translateService.instant('components.image-preview.PREVIEW_NOT_AVAILABLE');
  }

  public showNextItem(): void {
  	if (this.currentItemIndex < this.previewItemArrayLength - 1) {
  		this.currentItemIndex++;
  	} else {
  		this.currentItemIndex = 0;
  	}
  	this.imageError = false;
  }

  public showPreviousItem(): void {
  	if (this.currentItemIndex > 0) {
  		this.currentItemIndex--;
  	} else {
  		this.currentItemIndex = this.previewItemArrayLength - 1;
  	}
  	this.imageError = false;
  }

  public onShowImagePreviewGridViewModal() {
  	this.optiLogicModalService.open(
  		ImagePreviewDisplayModalComponent,
  		'lg',
  		{
  			initialState: {
  				imagePreviewData: this.imagePreviewData,
  				previewModalDisplayMode: PreviewDisplayMode.Grid
  			},
			  backdrop: true,
			  animated: false,
			  keyboard: true
  		});
  }

  public onImageError(): void {
  	this.imageError = true;
  }

  closeModal() {
  	this.bsModalRef.hide();
  }

  get currentItem(): ImagePreviewItem {
  	return this.imagePreviewItemArray[this.currentItemIndex];
  }

}
