import { Component, Input, OnInit } from '@angular/core';
import { PreviewDisplayMode } from '../model/preview-display-mode.enum';
import { ImagePreviewData, ImagePreviewItem } from './../model/image-preview.model';
@Component({
	selector: 'image-preview-grid-view',
	templateUrl: './image-preview-grid-view.component.html',
	styleUrls: ['./image-preview-grid-view.component.scss']
})
export class ImagePreviewGridViewComponent implements OnInit {

	@Input() imagePreviewData: ImagePreviewData;
	@Input() previewHeader: string;
	@Input() columnsCount: number = 2;
	@Input() isShowControlName: boolean = true;
	private imagePreviewItemArray: ImagePreviewItem[];
	public imagePreviewItemArrayLength: number;
	
	public imageRows: ImagePreviewItem[][];
	public PreviewDisplayMode = PreviewDisplayMode;
	constructor() { }

	ngOnInit(): void {
		this.imagePreviewItemArray = this.imagePreviewData.previewImages;
		this.imagePreviewItemArrayLength = this.imagePreviewItemArray.length;
		this.imageRows = this.convertToImageRows();
	}

	private convertToImageRows(): ImagePreviewItem[][] {
		const rowsCount: number = Math.ceil(this.imagePreviewItemArrayLength / this.columnsCount);
		const imageRows: ImagePreviewItem[][] = new Array(rowsCount).fill(null).map(() => []);
		this.imagePreviewItemArray.forEach((image, imageIndex) => {
			const rowIndex: number = Math.floor(imageIndex / this.columnsCount);
			imageRows[rowIndex].push(image);
		});

		return imageRows;
	}
	public getImagePreviewData(image): ImagePreviewData {
		return {
			contextName: this.imagePreviewData.contextName,
			previewImages: [image]
		};
	}
}
