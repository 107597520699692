import { SsmServiceResource } from './optimove-ssm.model';

export const SsmServiceEndPoints: SsmServiceResource = {
    AppRunMode: {
        functionName: 'GetAppRunMode',
        sessionVarName: 'appRunMode',
        promise: null
    },
    DefaultPage: {
        functionName: 'GetDefaultPage',
        sessionVarName: 'defaultPage',
        promise: null
    },
    DailyStatus: {
        functionName: 'getDataUpdateStatus',
        sessionVarName: 'dailyStatus',
        promise: null,
        resetOnRefresh: true
    },
    IsSettingsAvailable: {
        functionName: 'GetIsSettingsAvailable',
        sessionVarName: 'isSettingsAvailable',
        promise: null
    },
    CustomerAttributesEnums: {
        functionName: 'GetAllCustomerAttrEnums',
        sessionVarName: 'CustomerAttrEnums',
        promise: null
    },
    VisitorAttributesEnums: {
        functionName: 'GetAllVisitorAttrEnums',
        sessionVarName: 'VisitorAttributesEnums',
        promise: null
    },
    ReportFolder: {
        functionName: 'GetReportFolder',
        sessionVarName: 'reportFolder',
        promise: null
    },
    GroupId: {
        functionName: 'GetCurrentGroupId',
        sessionVarName: 'GroupId',
        promise: null
    },
    DefaultKpiCampaigns: {
        functionName: 'GetDefaultKpi',
        sessionVarName: 'DefaultKpiCampaigns',
        promise: null
    },
    DefaultVisitorsKpiCampaigns: {
        functionName: 'GetDefaultVisitorsKpi',
        sessionVarName: 'DefaultVisitorsKpiCampaigns',
        promise: null
    },
    MeasuresMapping: {
        functionName: 'GetAllCampaignMeasuresMapping',
        sessionVarName: 'MeasuresMapping',
        promise: null
    },
    VisitorsMeasuresMapping: {
        functionName: 'GetVisitorsCampaignMeasuresMapping',
        sessionVarName: 'VisitorsMeasuresMapping',
        promise: null
    },
    SystemTimeZones: {
        functionName: 'GetSystemTimeZones',
        sessionVarName: 'SystemTimeZones',
        promise: null
    },
    Version: {
        functionName: 'GetOptimoveVersion',
        sessionVarName: 'Version',
        promise: null
    },
    PiwikConfiguration: {
        functionName: 'GetIsPiwikConfigured',
        sessionVarName: 'IsPiwikEnabled',
        promise: null
    },
    DemoTenant: {
        functionName: 'GetisDemoModeConfigured',
        sessionVarName: 'isDemoMode',
        promise: null
    },
    AcademyLinks: {
        functionName: 'GetAcademyLinks',
        sessionVarName: 'AcademyLinks',
        promise: null
    },
    SiteName: {
        functionName: 'GetSiteName',
        sessionVarName: 'SiteName',
        promise: null
    },
    NotificationInfo: {
        functionName: 'GetNotificationInfo',
        sessionVarName: 'NotificationInfo',
        promise: null
    },
    CustomerAttributes: {
        functionName: 'GetCustomerAttributes',
        sessionVarName: 'CustomerAttributes',
        promise: null
    },
    DefaultKpiModel: {
        functionName: 'GetDefaultKpiModel',
        sessionVarName: 'DefaultKpiModel',
        promise: null
    },
    MaxCustomerAttributeSelection: {
        functionName: 'GetMaxCustomerAttributeSelection',
        sessionVarName: 'MaxCustomerAttributeSelection',
        promise: null
    },
    ClientTime: {
        functionName: 'GetClientTime',
        sessionVarName: 'ClientTime',
        promise: null
    },
    ClientTimezone: {
        functionName: 'GetClientTimezone',
        sessionVarName: 'ClientTimezone',
        promise: null
    },
    CampaignsTags: {
        functionName: 'GetCampaignsTags',
        sessionVarName: 'CampaignsTags',
        promise: null,
        resetOnRefresh: true
    },
    ExecutionChannelsPriority: {
        functionName: 'GetExecutionChannelsPriority',
        sessionVarName: 'ExecutionChannelsPriority',
        promise: null,
        resetOnRefresh: true
    },
    IsVisitorsLifecycleEnabled: {
        functionName: 'GetVisitorsLifecycleIndicator',
        sessionVarName: 'IsVisitorsLifecycleEnabled',
        promise: null
    },
    CampaignSettings: {
        functionName: 'GetCampaignSettings',
        sessionVarName: 'campaignSettings',
        promise: null
    },
    IsRealtimeEnable: {
        functionName: 'GetRealtimeIndicator',
        sessionVarName: 'IsRealtimeEnable',
        promise: null
    },
    IsOptitrackEnabled: {
        functionName: 'GetOptitrackIndicator',
        sessionVarName: 'IsOptitrackEnabled',
        promise: null
    },
    IsOptimailEnabled: {
        functionName: 'GetOptimailIndicator',
        sessionVarName: 'IsOptimailEnabled',
        promise: null
    },
    CsmConfiguration: {
        functionName: 'GetCsmConfiguration',
        sessionVarName: 'CsmConfiguration',
        promise: null
    },
    SuccessHubFeatureFlag: {
        functionName: 'GetSuccessHubFeatureFlag',
        sessionVarName: 'SuccessHubFeatureFlag',
        promise: null,
        resetOnRefresh: true
    },
    TenantDetails: {
        functionName: 'GetTenantDetails',
        sessionVarName: 'tenantDetails',
        promise: null
    },
    FeatureFlags: {
        functionName: 'GetAllFeatureFlags',
        sessionVarName: 'FeatureFlags',
        promise: null
    },
    ReCaptchaPublicKey: {
        functionName: 'GetReCaptchaPublicKey',
        sessionVarName: 'ReCaptchaPublicKey',
        promise: null
    },
    TenantUsers: {
        functionName: 'GetTenantUsers',
        sessionVarName: 'TenantUsers',
        promise: null
    },
    DataDogDetails: {
        functionName: 'GetDataDogDetails',
        sessionVarName: 'DataDogDetails',
        promise: null
    },
    MicrofrontendUrls: {
        functionName: 'GetMicrofrontendUrls',
        sessionVarName: 'MicrofrontendUrls',
        promise: null
    },
    MicrofrontendModules: {
        functionName: 'GetMicrofrontendModules',
        sessionVarName: 'MicrofrontendModules',
        promise: null
    },
    RiskOfChurnGroups: {
        functionName: 'GetRiskOfChurnGroups',
        sessionVarName: 'RiskOfChurnGroups',
        promise: null
    },
    ConversionRateGroups: {
        functionName: 'GetConversionRateGroups',
        sessionVarName: 'ConversionRateGroups',
        promise: null
    },
    ReactivationRateGroups: {
        functionName: 'GetReactivationRateGroups',
        sessionVarName: 'ReactivationRateGroups',
        promise: null
    },
    PreferenceCenterDetails: {
        functionName: 'GetPreferenceCenterDetails',
        sessionVarName: 'PreferenceCenterDetails',
        promise: null
    },
    IsOptimoveEngage: {
        functionName: 'IsOptimoveEngage',
        sessionVarName: 'IsOptimoveEngage',
        promise: null
    }
};