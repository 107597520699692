
import {NgModule} from "@angular/core";
import {AutoFocusDirective} from "./autoFocus.directive";
import {AsyncValidationDirective} from "./customValidation/asyncValidation.directive";
import {MaxValidationDirective} from "./customValidation/maxValidation.directive";
import {MinValidationDirective} from "./customValidation/minValidation.directive";
import {EmailValidationDirective} from "./customValidation/emailValidation.directive";
import {UserNameValidationDirective} from "./customValidation/userNameValidation.directive";
import {CannotStartWithNumbersValidationDirective} from "./customValidation/cannotStartWithNumbersValidation.directive";
import {OptibotInsightDirective} from "./optibotInsight/optibotInsight.directive";
import {SharedModule} from "../shared.module";
import {CsvExporter} from "./csvExporter.directive";
import {ProvideNestedForm} from "./provideNestedForm.directive";
import {OptitrackDirective} from "./optitrack/optitrack.directive";
import {GTMDataLayerService} from "./optitrack/optitrack.service";
import {MinDateRangeValidationDirective} from "./customValidation/minDateRangeValidation.directive";
import {EllipsisTitleDirective} from "./ellipsisTitle/ellipsisTitle.directive";
import {OnlyDigitsDirective} from "./onlyDigits.directive";
import { DirectivesModule as PackageDirectives } from '@optimove/ui-sdk/common/directives';
import {ClickOutsideDirective} from "./clickOutside.directive";
import { IfFeatureFlagDirective } from './ifFeatureFlag.directive';
import { UrlValidationDirective } from './customValidation/urlValidation.directive';
import { HideOnOptimoveEngageModeDirective } from './hide-on-optimove-engage-mode.directive';

@NgModule({
    imports: [
        SharedModule,
        PackageDirectives
    ],
providers: [
        // {provide: 'gtmDataLayerService', useFactory: i => i.get('gtmDataLayerService'), deps: ['$injector']},
        GTMDataLayerService
    ],
    declarations: [
        AutoFocusDirective,
        AsyncValidationDirective,
        MaxValidationDirective,
        MinValidationDirective,
        MinDateRangeValidationDirective,
        EmailValidationDirective,
        UserNameValidationDirective,
        CannotStartWithNumbersValidationDirective,
        OptibotInsightDirective,
        OptitrackDirective,
        CsvExporter,
        ProvideNestedForm,
        EllipsisTitleDirective,
        OnlyDigitsDirective,
        ClickOutsideDirective,
        IfFeatureFlagDirective,
        UrlValidationDirective,
        HideOnOptimoveEngageModeDirective
    ],
    exports: [
        AutoFocusDirective,
        AsyncValidationDirective,
        MaxValidationDirective,
        MinValidationDirective,
        MinDateRangeValidationDirective,
        EmailValidationDirective,
        UserNameValidationDirective,
        CannotStartWithNumbersValidationDirective,
        OptibotInsightDirective,
        OptitrackDirective,
        CsvExporter,
        ProvideNestedForm,
        EllipsisTitleDirective,
        OnlyDigitsDirective,
        PackageDirectives,
        ClickOutsideDirective,
        IfFeatureFlagDirective,
        UrlValidationDirective,
        HideOnOptimoveEngageModeDirective
    ]
})
export class DirectivesModule {

}
