import { Component, Input } from '@angular/core';

@Component({
	selector: 'preview-not-available',
	templateUrl: './preview-not-available.component.html',
	styleUrls: ['./preview-not-available.component.scss']
})
export class PreviewNotAvailableComponent {

  @Input() isLargeSize: boolean;
  @Input() message: string;
  constructor() { }

}
