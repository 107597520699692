<div class="item-preview-carousel-modal">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div>
        <h2 ellipsis-title>{{ currentItem.name }}</h2>
      </div>

      <div class="right-col" *ngIf="previewItemArrayLength > 1">
        <button (click)="onShowImagePreviewGridViewModal()" class="btn-grid-view btn-link">
          {{ "components.image-preview.SHOW_ALL" | translate }}
        </button>

        <div class="item-browse-state">
          {{
            "components.image-preview.MODAL_BROWSE_STATE"
              | translate : {currentItemIndex: currentItemIndex + 1, previewItemArrayLength: previewItemArrayLength}
          }}
        </div>
      </div>
    </div>

    <div class="panel-body">
      <button *ngIf="previewItemArrayLength > 1" class="btn-link item-browse-btn btn-browse-left" (click)="showPreviousItem()">
        <i class="material-icons">chevron_left</i>
      </button>

      <figure *ngIf="!imageError">
        <img [src]="currentItem.url" (error)="onImageError()" />
      </figure>
      <preview-not-available *ngIf="imageError" [isLargeSize]="true" [message]="previewNotAvailableMessage"></preview-not-available>

      <button *ngIf="previewItemArrayLength > 1" class="btn-link item-browse-btn btn-browse-right" (click)="showNextItem()">
        <i class="material-icons">chevron_right</i>
      </button>
    </div>
  </div>
</div>
