<span *ngIf="isShowHeader">
  <p [ngClass]="nameClass ? nameClass : ''" ellipsis-title [lines]="2">{{ currentItem.name }}</p>
  <p [ngClass]="descriptionClass ? descriptionClass : ''" ellipsis-title [lines]="2" *ngIf="currentItem.description">
    {{ currentItem.description }}
  </p>
</span>

<div class="display-container">
  <button *ngIf="!imageError" class="image-preview btn-link" (click)="onShowImagePreviewCarouselModal()">
    <div class="hover-preview"></div>
    <div class="hover-preview-text">{{ "components.image-preview.IMAGE_PREVIEW" | translate }}</div>
    <figure>
      <img [src]="currentItem.url" (error)="onImageError()" />
    </figure>
  </button>

  <preview-not-available *ngIf="imageError" [isLargeSize]="false" [message]="previewNotAvailableMessage"></preview-not-available>
</div>

<div class="multi-item-browser" *ngIf="imagePreviewItemArrayLength > 1">
  <div class="item-browsing">
    <button class="btn-link" (click)="showPreviousItem()">
      <i class="material-icons">chevron_left</i>
    </button>
    <span>{{
      "components.image-preview.BROWSE_STATE"
        | translate : {currentItemIndex: currentItemIndex + 1, imagePreviewItemArrayLength: imagePreviewItemArrayLength}
    }}</span>
    <button class="btn-link" (click)="showNextItem()">
      <i class="material-icons">chevron_right</i>
    </button>
  </div>
  <button class="btn-link btn-show-all" (click)="showAllItems()">
    {{ "components.image-preview.SHOW_ALL" | translate }}
  </button>
</div>
