import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OptiLogicModalService } from '../../optiLogicModal/optiLogicModal.service';
import { ImagePreviewData, ImagePreviewItem } from '../model/image-preview.model';
import { PreviewDisplayMode } from '../model/preview-display-mode.enum';
import { ImagePreviewDisplayModalComponent } from './../image-preview-display-modal/image-preview-display-modal.component';

@Component({
	selector: 'image-preview',
	templateUrl: './image-preview.component.html',
	styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {

	@Input() imagePreviewData: ImagePreviewData;
	@Input() isShowHeader: boolean = true;
	@Input() previreModalDisplayMode: PreviewDisplayMode = PreviewDisplayMode.Grid;
	@Input() nameClass: string;
	@Input() descriptionClass: string;

	public imageError: boolean = false;
	public previewNotAvailableMessage: string;
	public imagePreviewItemArrayLength: number;
	public currentItemIndex: number = 0;

	public imagePreviewItemArray: ImagePreviewItem[];
	public PreviewDisplayMode = PreviewDisplayMode;
	constructor(
		private translateService: TranslateService,
		private optiLogicModalService: OptiLogicModalService) {
	}

	ngOnInit(): void {
		this.previewNotAvailableMessage = this.translateService.instant('components.image-preview.PREVIEW_NOT_AVAILABLE');
		this.imagePreviewItemArray = this.imagePreviewData.previewImages;
		this.imagePreviewItemArrayLength = this.imagePreviewItemArray.length;
	}

	public onImageError(): void {
		this.imageError = true;
	}

	public showNextItem(): void {
  	if (this.currentItemIndex < this.imagePreviewItemArrayLength - 1) {
  		this.currentItemIndex++;
  	} else {
  		this.currentItemIndex = 0;
  	}
  	this.imageError = false;
	}

	public showPreviousItem(): void {
  	if (this.currentItemIndex > 0) {
  		this.currentItemIndex--;
  	} else {
  		this.currentItemIndex = this.imagePreviewItemArrayLength - 1;
  	}
  	this.imageError = false;
	}

	public showAllItems() {
  	this.optiLogicModalService.open(
  		ImagePreviewDisplayModalComponent,
  		'lg',
  		{
  			initialState: {
  				imagePreviewData: this.imagePreviewData,
  				previewModalDisplayMode: this.previreModalDisplayMode
  			},
			  backdrop: true,
			  animated: false,
			  keyboard: true
  		});
	}

	public onShowImagePreviewCarouselModal() {
  	this.optiLogicModalService.open(
  		ImagePreviewDisplayModalComponent,
  		'lg', {
  		initialState: {
  			imagePreviewData: this.imagePreviewData,
  			previewModalDisplayMode: PreviewDisplayMode.Carousel
  		},
  		backdrop: true,
  		animated: false,
  		keyboard: true
  	});
	}

	get currentItem(): ImagePreviewItem {
		return this.imagePreviewItemArray[this.currentItemIndex];
	}

}
