import { Component, Injector, ViewEncapsulation, ChangeDetectorRef, NgZone } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  ActivationStart,
  ActivatedRouteSnapshot
} from '@angular/router';
import { NavbarWrapperService, NavbarStateFlags, OptibotNotifications } from './services/navbarWrapper.service';
import { DailyStatus } from './models/dailyServiceStatus.model'
import { Subscription } from 'rxjs';
import { SignalrService } from "./services/signalr.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { enGbLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { WindowRefService } from './services/windowRef.service';

import { datadogRum } from "@datadog/browser-rum";
import { FullPageLoaderService } from './services/fullPageLoader.service';
import {
  AngularRouterUIRouterAdapter,
  RouterAdapterLoadingState
} from './routing/angularRouterUIRouterAdapter.service';
import { ZendeskWidgetService } from "./services/zendeskWidget.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent {
  public ngBootstrapStatus = NgBoostrapStatus.uninitialized;
  public ngBootstrapStatusAvailable = NgBoostrapStatus;

  public navbarState: NavbarStateFlags = {
    isDemoMode: true,
    isSettingsVisible: true,
  };
  public dailyStatus: DailyStatus | undefined;
  public optibotNotificationsCount: number;

  private dailyStatusSubscription: Subscription;
  private optibotNotificationSubscription: Subscription;

  private _angularJsIndexService: any;
  private _lastActivationStartEvent: ActivationStart;
  public isLoading: boolean = false;
  public text: string = null;

  private get angularJsIndexService(): any {
    if (!this._angularJsIndexService) {
      this._angularJsIndexService = this.injector.get('runService');
    }
    return this._angularJsIndexService;
  }

  constructor(
    public translate: TranslateService,
    private navbarWrapperService: NavbarWrapperService,
    private router: Router,
    private signalR: SignalrService,
    private localeService: BsLocaleService,
    private injector: Injector,
    private window: WindowRefService,
    private loaderService: FullPageLoaderService,
    private cd: ChangeDetectorRef,
    private routerService: AngularRouterUIRouterAdapter,
    private ngZone: NgZone,
    private zendeskWidgetService: ZendeskWidgetService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');

    // Set week start to be monday
    defineLocale(enGbLocale.abbr, enGbLocale);
    this.localeService.use(enGbLocale.abbr);

    this.signalR.init();
  }

  ngOnInit() {
    this.routerService.routerAdapterLoadingState
      .subscribe((state: RouterAdapterLoadingState) => {
        let shouldShow = state === RouterAdapterLoadingState.loading;
        this.ngZone.run(() => {
          this.isLoading = shouldShow;
          this.cd.detectChanges();
        });
      });
    if (!document.getElementById("ze-snippet")) {
      this.zendeskWidgetService.authenticateWidget();
      this.window.nativeWindow.initWidgetUrl();
    }

    this.window.nativeWindow.initOptiGenieWidgetUrl();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.onNgBoostrapped();
        this.zendeskWidgetService.setZendeskWidgetDefaultState()
      }

      if (event instanceof ActivationStart) {
        this._lastActivationStartEvent = event;
      }

      if (event instanceof NavigationEnd) {
        this.zendeskWidgetService.setHelpCenterSuggestions();
      }

    });

    this.loaderService.showLoader.subscribe(
      showLoader => {
        this.isLoading = showLoader;
        this.cd.detectChanges();
      }
    )

    this.loaderService.text.subscribe(
      text => {
        this.text = text;
        this.cd.detectChanges();
      }
    )

    this.window.nativeWindow.addEventListener("message", event => {
      if (event.data.action === "openPendo") {
        const pendo = this.window.nativeWindow.pendo;
        pendo.showGuideById(event.data.pendoGuideId);
        event.preventDefault();
      }
    });
    // For some legacy pages we occasionally have grey-screen and loading starts only after click or a long wait time.
    // Here we do force change detection only for initial navigation completion to fix this problem.
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd), take(1)).subscribe(() => {
    //   if (this.isInitialChangeDetectionRequired(this._lastActivationStartEvent.snapshot)) {
    //     this.cd.detectChanges();
    //   }
    // });
  }

  isInitialChangeDetectionRequired(snapshot: ActivatedRouteSnapshot): boolean {
    let currentItem = snapshot;
    do {
      if (currentItem.data?.initialChangeDetectionRequired === true)
        return currentItem.data.initialChangeDetectionRequired;
      currentItem = currentItem.parent;
    } while (currentItem != null);

    return false;
  }

  ngOnDestroy() {
    this.dailyStatusSubscription && this.dailyStatusSubscription.unsubscribe();
    this.optibotNotificationSubscription && this.optibotNotificationSubscription.unsubscribe();
  }

  // isNgBootstrapped is by default false as its an async task.
  // Since routing only starts after ng bootstraps successfully it's a good way to detect its change.
  private onNgBoostrapped() {
    if (this.ngBootstrapStatus !== NgBoostrapStatus.uninitialized) {
      return;
    }

    this.ngBootstrapStatus = NgBoostrapStatus.inProgress;
    this.angularJsIndexService.init();

    this.dailyStatusSubscription = this.navbarWrapperService.dailyStatusSubject
      .subscribe((state: DailyStatus) => {
        this.dailyStatus = state;
      });
    this.optibotNotificationSubscription = this.navbarWrapperService.optibotNotificationsSubject
      .subscribe((notification: OptibotNotifications) => {
        this.optibotNotificationsCount = notification.unread;
      });
    this.navbarWrapperService.onInitialize()
      .subscribe(state => this.navbarState = state);

    this.navbarWrapperService.initListeners();

    this.ngBootstrapStatus = NgBoostrapStatus.complete;
    this.initDataDog();
    this.ngZone.run(() => {
      this.cd.detectChanges();
    });
  }

  private getCookie(key: string): string {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
  }

  private initDataDog() {

    const tenantDetails = JSON.parse(sessionStorage.getItem("tenantDetails"));


    if (sessionStorage.getItem("DataDogDetails")) {
      const dataDog: DataDogSettings = JSON.parse(sessionStorage.getItem("DataDogDetails"));

      if (dataDog && dataDog.isDataDog === true) {

        datadogRum.setUser({
          name: tenantDetails.userName,
          tenantId: tenantDetails.tenantId
        });

        datadogRum.init({
          applicationId: dataDog.applicationId,
          clientToken: dataDog.clientToken,
          site: dataDog.site,
          service: dataDog.service,
          // Specify a version number to identify the deployed version of your application in Datadog 
          version: dataDog.version ? dataDog.version : undefined,
          env: dataDog.env ? dataDog.env : undefined,
          sampleRate: dataDog.sampleRate,
          trackInteractions: dataDog.trackInteractions,
          defaultPrivacyLevel: dataDog.defaultPrivacyLevel
        });

        datadogRum.startSessionReplayRecording();
      }
    }
  }
}

export enum NgBoostrapStatus {
  uninitialized,
  inProgress,
  complete
}

export interface DataDogSettings {
  isDataDog: boolean;
  applicationId: string;
  clientToken: string;
  site: string;
  version: string | undefined;
  env: string | undefined;
  service: string;
  sampleRate: number;
  trackInteractions: boolean;
  defaultPrivacyLevel: any;

}