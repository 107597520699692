<div class="preview-modal-layout">
  <button class="btn-link btn-close" (click)="closeModal()">
    <i class="material-icons">close</i>
  </button>

  <div class="modal-container">
    <ng-container [ngSwitch]="previewModalDisplayMode">
      <image-preview-carousel-modal
        *ngSwitchCase="PreviewDisplayMode.Carousel"
        [imagePreviewData]="imagePreviewData"></image-preview-carousel-modal>
      <image-preview-grid-view *ngSwitchCase="PreviewDisplayMode.Grid" [imagePreviewData]="imagePreviewData"></image-preview-grid-view>
    </ng-container>
  </div>
</div>
