<div class="overlay" *ngIf="show">
    <div class="loader-lg">
        <div class="new-loader vertical-center text-center">
            <div class="loader-inner">
                <div class="loading-text" *ngIf="text">{{text}}</div>
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</div>
